<ng-container *transloco="let t; read: 'toastMessages'">
<div
  (mouseenter)="onMouseEnter()"
  (mouseleave)="onMouseLeave()"
  [attr.data-cy]="'toast-notification'"
  aria-live="assertive"
  class="mb-10 sm:mb-0 fixed relative flex items-end px-4 pointer-events-none sm:p-6 sm:items-start">
  <div class="w-full flex flex-col items-center space-y-4 sm:items-end">
    <div
      [@fadeAnimation]="{value: animationState, params:
        { fadeIn: 300, fadeOut: 2500 }}"
      (@fadeAnimation.done)="onFadeFinished($event)"
      class="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
      <div class="p-4">
        <div class="flex items-start">
          <ng-container *ngIf="!data.template; else templateRef">
            <div class="flex-shrink-0">
            <!-- ICON-->
              <ng-container [ngSwitch]="data.type">
                <mkx-icon
                  *ngSwitchCase="'success'"
                  [icon]="'check-circle'"
                  [color]="'green'"
                  [size]="'6'">
                </mkx-icon>
                <mkx-icon
                  *ngSwitchCase="'error'"
                  [icon]="'x-circle'"
                  [color]="'red'"
                  [size]="'6'">
                </mkx-icon>
                <mkx-icon
                  *ngSwitchCase="'warning'"
                  [icon]="'exclamation-circle'"
                  [color]="'yellow'"
                  [size]="'6'">
                </mkx-icon>
                <mkx-icon
                  *ngSwitchCase="'info'"
                  [icon]="'information-circle'"
                  [color]="'blue'"
                  [size]="'6'">
                </mkx-icon>
              </ng-container>
            </div>
            <div class="ml-3 w-0 flex-1 pt-0.5">
            <!-- TITLE -->
              <p class="text-base font-medium text-gray-900">
                <ng-container *ngIf="data.title; else defaultTitle">
                  {{t(data.title)}}
                </ng-container>
                <ng-template #defaultTitle>
                  <ng-container [ngSwitch]="data.type">
                    <ng-container *ngSwitchCase="'success'">{{t('successfully_saved')}}</ng-container>
                    <ng-container *ngSwitchCase="'error'">{{t('error_occurred')}}</ng-container>
                    <ng-container *ngSwitchCase="'warning'">{{t('warning')}}</ng-container>
                    <ng-container *ngSwitchCase="'info'">{{t('info')}}</ng-container>
                  </ng-container>
                </ng-template>
              </p>
              <!-- TEXT -->
              <p class="mt-1 text-base text-gray-500 break-word">
                <ng-container *ngIf="data.text; else defaultText">
                  <ng-container *ngIf="false === shouldTranslate">{{data.text}}</ng-container>
                  <ng-container *ngIf="shouldTranslate">{{t(data.text)}}</ng-container>
                </ng-container>
                <ng-template #defaultText>
                  <ng-container [ngSwitch]="data.type">
                    <ng-container *ngSwitchCase="'success'">{{t('success_message')}}</ng-container>
                    <ng-container *ngSwitchCase="'error'">{{t('error_message')}}</ng-container>
                  </ng-container>
                </ng-template>
              </p>
            </div>
            <div class="ml-4 flex-shrink-0 flex">
              <button
                (click)="close()"
                class="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                <span class="sr-only">Close</span>
                <mkx-icon [attr.data-cy]="'button_close_notification'" [icon]="'x'" [size]="'4'"></mkx-icon>
              </button>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
</ng-container>
<!-- for template rendering -->
<ng-template #templateRef>
  <ng-container *ngTemplateOutlet="data.template; context: data.templateContext">
  </ng-container>
</ng-template>
