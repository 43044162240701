import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { ROLES } from '@mkx/constants/roles';

@Injectable({
  providedIn: 'root',
})
export class JwtService {
  private jwtHelper: JwtHelperService;

  constructor() {
    this.jwtHelper = new JwtHelperService();
  }

  public getToken(): string {
    return window.localStorage['jwtToken'];
  }

  public saveToken(token: string) {
    window.localStorage['jwtToken'] = token;
  }

  public destroyToken() {
    window.localStorage.removeItem('jwtToken');
  }

  private isExpired(): boolean {
    return this.jwtHelper.isTokenExpired(this.getToken());
  }

  private getUserRoles(): ROLES[] {
    return (
      (this.jwtHelper.decodeToken(this.getToken())?.roles as ROLES[]) || []
    );
  }

  public isAuthenticated(): boolean {
    return this.getToken() !== '' && this.getToken() !== undefined;
  }

  public hasRoles(roles: ROLES[]): boolean {
    const userRoles = this.getUserRoles();
    const intersection = roles.filter(x => userRoles.includes(x));

    return !!intersection.length;
  }
}
