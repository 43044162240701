export const ICON_TYPE = {
  EXTERNAL: 'external',
  ICON: 'icon',
  THUMBNAIL: 'thumbnail',
} as const;
export type ICON_TYPE = (typeof ICON_TYPE)[keyof typeof ICON_TYPE];

export class IconModel {
  private source: string;
  private type: string;
  private size: string;

  constructor(source: string, type: string, size = 'w-5 h-5') {
    this.source = source;
    this.type = type;
    this.size = size;
  }

  public isIcon(): boolean {
    return this.type === ICON_TYPE.ICON;
  }

  public isExternal(): boolean {
    return this.type === ICON_TYPE.EXTERNAL;
  }

  public isThumbnail(): boolean {
    return this.type === ICON_TYPE.THUMBNAIL;
  }

  public getSource(): string {
    return this.source;
  }

  public getSize(): string {
    return this.size;
  }
}
