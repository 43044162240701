import { ApiResponseEntityBuilderInterface } from '@mkx/interfaces/common/api-response-entity-builder.interface';
import { staticImplementsDecorator } from '@mkx/decorators/static-implements.decorator';
import { Expose, plainToInstance } from 'class-transformer';
import 'reflect-metadata';
import { SelectOptionInterface } from '@mkx/interfaces/common/select-option.interface';
import { IconModel } from '@mkx/models/icon.model';
import { NETWORK_ADS } from '@mkx/interfaces/common/INetworkType';

@staticImplementsDecorator<ApiResponseEntityBuilderInterface>()
export class NetworkAccountModel
  implements SelectOptionInterface<NetworkAccountModel>
{
  @Expose({ groups: ['get'], name: 'id' })
  private id: number;

  @Expose({ groups: ['get', 'post', 'put'], name: 'network' })
  private network: string;

  @Expose({ groups: ['get', 'post', 'put'], name: 'network_id' })
  private networkId: string;

  @Expose({ groups: ['get'], name: 'bidding_currency' })
  private biddingCurrency: string;

  @Expose({ groups: ['get'], name: 'name' })
  private name: string;

  @Expose({ groups: ['get'], name: 'time_zone' })
  private timeZone: string;

  @Expose({
    groups: ['get', 'post', 'put'],
    name: 'include_media_cost_on_invoice',
  })
  private includeMediaCostOnInvoice: boolean;

  @Expose({ groups: ['get'], name: 'is_cancelled' })
  private isCancelled: boolean;

  @Expose({ groups: ['get'], name: 'is_suspended' })
  private isSuspended: boolean;

  constructor(
    network: string,
    networkId: string,
    includeMediaCostOnInvoice: boolean
  ) {
    this.network = network;
    this.networkId = networkId;
    this.includeMediaCostOnInvoice = includeMediaCostOnInvoice;
  }

  static buildFromAPIResponse(body: any): any {
    return plainToInstance(NetworkAccountModel, body, { groups: ['get'] });
  }

  public getId(): number {
    return this.id;
  }

  public getNetwork(): string {
    return this.network;
  }

  public setNetwork(value: string) {
    this.network = value;
  }

  public getNetworkId(pretty = false): string {
    if (pretty && this.getNetwork() === NETWORK_ADS.GOOGLE) {
      return this.networkId.replace(/#.*$/, '');
    }

    return this.networkId;
  }

  public setNetworkId(value: string) {
    this.networkId = value;
  }

  public getBiddingCurrency(): string {
    return this.biddingCurrency;
  }

  public getName(): string {
    return this.name;
  }

  public setName(value: string) {
    this.name = value;
  }

  public getTimeZone(): string {
    return this.timeZone;
  }

  public getIncludeMediaCostOnInvoice(): boolean {
    return this.includeMediaCostOnInvoice;
  }

  public getIsCancelled(): boolean {
    return this.isCancelled;
  }

  public getIsSuspended(): boolean {
    return this.isSuspended;
  }

  public getIsActive(): boolean {
    return !this.isCancelled && !this.isSuspended;
  }

  selectOptionGetDescription(): string | null {
    return this.networkId;
  }

  selectOptionGetIcon(): IconModel | null {
    return null;
  }

  public selectOptionGetColorClass(): string | null {
    return null;
  }

  selectOptionGetIdentifier(): any {
    return this.id ? this.id : this.networkId;
  }

  selectOptionGetLabel(): string {
    return this.name;
  }

  selectOptionGetLabelProperty(): string {
    return 'name';
  }

  selectOptionGetValue(): NetworkAccountModel {
    return this;
  }

  selectOptionIsDisabled(): boolean {
    return false;
  }

  selectOptionIsSelected(): boolean {
    return false;
  }

  selectOptionDeselect() {}

  selectOptionLabelIsUrl(): boolean {
    return false;
  }

  selectOptionSelect() {}
}
