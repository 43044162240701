import { Expose, plainToInstance } from 'class-transformer';

export class TokenModel {
  @Expose({ groups: ['get'], name: 'token' })
  private _token: string;

  @Expose({ groups: ['get'], name: 'refresh_token' })
  private _refreshToken: string;

  @Expose({ groups: ['get'], name: 'code' })
  private _code: string | null;

  static buildFromAPIResponse(body: any): any {
    return plainToInstance(TokenModel, body, { groups: ['get'] });
  }

  public get token(): string {
    return this._token;
  }

  public get refreshToken(): string {
    return this._refreshToken;
  }

  public get code(): string | null {
    return this._code;
  }

  public get mailCode(): boolean {
    return this._code === 'mail';
  }
}
