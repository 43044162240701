import { Injectable } from '@angular/core';
import * as Sentry from '@sentry/browser';
import { UserModel } from '@mkx/features/users/models/user.model';

@Injectable({
  providedIn: 'root',
})
export class SentryScopeService {
  monitorUser(user: UserModel): void {
    Sentry.configureScope(scope => {
      scope.setUser({
        id: user?.getId() as any,
        username: user?.getName(),
        email: user?.getEmail(),
      });
    });
  }
}
