import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { ToastService } from '@mkx/services/toaster/toast-service/toast.service';

@Injectable({
  providedIn: 'root',
})
export class ErrorHandlerService {
  genericMessage: string;
  currentMessage: string;
  constructor(private toastService: ToastService) {}

  // todo: added error ui-display if needed
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public handleError(operation = 'operation', type, withDefaultToaster = true) {
    return (errorResponse: any): Observable<any> => {
      if (errorResponse.error && errorResponse.error.error && type !== 'get') {
        // const message = this.genericMessage;
      } else if (
        errorResponse.error &&
        errorResponse.error.message &&
        type !== 'get'
      ) {
        // const message = `${this.currentMessage}: ${errorResponse.error.message}`;
      }
      if (errorResponse?.status !== 401 && withDefaultToaster) {
        this.toastService.show({ type: 'error' });
      }
      return throwError(errorResponse);
    };
  }
}
