import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { UserModel } from '../../models/user.model';
import { map } from 'rxjs/operators';
import { ApiResponseModel } from '@mkx/models/api-response.model';

@Injectable({
  providedIn: 'root',
})
export class UserPutService {
  constructor(private http: HttpClient) {}

  put(userId, payload): Observable<UserModel> {
    const url = `/users/${userId}`;

    return this.http.put<UserModel>(url, payload).pipe(
      map(res => {
        return new ApiResponseModel(UserModel, {
          data: res,
        }).getItem<UserModel>();
      })
    );
  }
}
