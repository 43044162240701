export const PLATFORM = {
  YOUTUBE: 'youtube',
  INSTAGRAM: 'instagram',
  FACEBOOK: 'facebook',
  GOOGLE: 'google_ads',
  TIKTOK: 'tiktok',
  DV360_ADS: 'dv360_ads',
  ADFORM_ADS: 'adform_ads',
  SPOTIFY: 'spotify',
  TWITTER: 'twitter',
  OMNITURE: 'omniture',
  META: 'meta',
  LINKEDIN: 'linkedin',
  PINTEREST: 'pinterest',
  AMAZON_ADS: 'amazon_ads',
  TWITTER_ADS: 'twitter_ads',
  SNAPCHAT_ADS: 'snapchat_ads',
  SNAPCHAT: 'snapchat',
  REDDIT_ADS: 'reddit_ads',
  REDDIT: 'reddit',
} as const;
export type PLATFORM = (typeof PLATFORM)[keyof typeof PLATFORM];

export const SOCIAL_MEDIA = {
  TWITTER: 'twitter',
} as const;
export type SOCIAL_MEDIA = (typeof SOCIAL_MEDIA)[keyof typeof SOCIAL_MEDIA];

export const ANALYTIC_VENDOR = {
  GOOGLE: 'google_analytics',
} as const;
export type ANALYTIC_VENDOR =
  (typeof ANALYTIC_VENDOR)[keyof typeof ANALYTIC_VENDOR];
