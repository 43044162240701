import { Expose, Exclude, plainToInstance } from 'class-transformer';
import { staticImplementsDecorator } from '@mkx/decorators/static-implements.decorator';
import { ApiResponseEntityBuilderInterface } from '@mkx/interfaces/common/api-response-entity-builder.interface';
import { SelectOptionInterface } from '@mkx/interfaces/common/select-option.interface';
import { ICON_TYPE, IconModel } from '@mkx/models/icon.model';

@staticImplementsDecorator<ApiResponseEntityBuilderInterface>()
export class BasicSelectOptionModel
  implements SelectOptionInterface<BasicSelectOptionModel>
{
  @Exclude()
  private selected = false;

  @Expose({ groups: ['get'], name: 'id' })
  private id: string;

  @Expose({ groups: ['get'], name: 'name' })
  private name: string;

  @Expose({ groups: ['get'], name: 'icon' })
  private icon: string | null;

  @Expose({ groups: ['get'], name: 'icon_type' })
  private iconType: ICON_TYPE | null;

  @Expose({ groups: ['get'], name: 'description' })
  private description: string | null;

  @Expose({ groups: ['get'], name: 'disabled' })
  private disabled: boolean | null;

  static buildFromAPIResponse(body: any): any {
    return plainToInstance(BasicSelectOptionModel, body, { groups: ['get'] });
  }

  public getId(): string {
    return this.id;
  }

  public getName(): string {
    return this.name;
  }

  public selectOptionGetValue(): BasicSelectOptionModel {
    return this;
  }

  public selectOptionGetLabel(): string {
    return this.name;
  }

  public selectOptionGetLabelProperty(): string {
    return 'name';
  }

  public selectOptionIsDisabled(): boolean {
    return Boolean(this.disabled);
  }

  public selectOptionSelect() {
    this.selected = true;
  }

  public selectOptionDeselect() {
    this.selected = false;
  }

  public selectOptionIsSelected(): boolean {
    return this.selected;
  }

  public selectOptionGetIdentifier(): any {
    return this.id;
  }

  public selectOptionGetIcon(): IconModel | null {
    if (!this.icon) {
      return null;
    }
    switch (this.iconType) {
      case ICON_TYPE.ICON:
        return new IconModel(this.icon, ICON_TYPE.ICON);
      case ICON_TYPE.EXTERNAL:
        return new IconModel(this.icon, ICON_TYPE.EXTERNAL);
      case ICON_TYPE.THUMBNAIL:
        return new IconModel(this.icon, ICON_TYPE.THUMBNAIL);
      default:
        return new IconModel(this.icon, ICON_TYPE.ICON);
    }
  }

  public selectOptionGetColorClass(): string | null {
    return null;
  }

  public selectOptionGetDescription(): string | null {
    return this.description;
  }

  public selectOptionLabelIsUrl(): boolean {
    return false;
  }
}
