import {
  AnimationTriggerMetadata,
  trigger,
  state,
  transition,
  style,
  animate,
} from '@angular/animations';

export const toastAnimations: {
  readonly fadeToast: AnimationTriggerMetadata;
} = {
  fadeToast: trigger('fadeAnimation', [
    state('default', style({ opacity: 1, transform: 'translateX(0)' })),
    transition(':enter', [
      style({ opacity: 0, transform: 'translateX(0.5rem)' }),
      animate('{{ fadeIn }}ms'),
    ]),
    transition(
      'default => closing',
      animate('{{ fadeOut }}ms', style({ opacity: 0 }))
    ),
  ]),
};

export type ToastAnimationState = 'default' | 'closing';
