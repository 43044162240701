import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiResponseModel } from '@mkx/models/api-response.model';
import { UserModel } from '@mkx/features/users/models/user.model';

@Injectable({
  providedIn: 'root',
})
export class UserProfileGetService {
  constructor(private http: HttpClient) {}

  me(): Observable<UserModel> {
    return this.http.get<any>(`/me`, {}).pipe(
      map(res => {
        return new ApiResponseModel(UserModel, {
          data: res,
        }).getItem<UserModel>();
      })
    );
  }
}
