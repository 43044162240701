import { Expose } from 'class-transformer';
import 'reflect-metadata';

export class EnvironmentStyleModel {
  @Expose({ groups: ['read'], name: 'primary_color' })
  private primaryColor: string;

  @Expose({ groups: ['read'], name: 'secondary_color' })
  private secondaryColor: string;

  @Expose({ groups: ['read'], name: 'white_logo' })
  private whiteLogo: string;

  @Expose({ groups: ['read'], name: 'black_logo' })
  private blackLogo: string;

  @Expose({ groups: ['read'], name: 'login_background_image' })
  private loginBackgroundImage: string | null;

  @Expose({ groups: ['read'], name: 'company_name' })
  private companyName: string;

  @Expose({ groups: ['read'], name: 'company_url' })
  private companyUrl: string;

  @Expose({ groups: ['read'], name: 'status_url' })
  private statusUrl: string | null;

  public getPrimaryColor(): string {
    return this.primaryColor;
  }

  public getSecondaryColor(): string {
    return this.secondaryColor;
  }

  public getWhiteLogo(): string {
    return this.whiteLogo;
  }

  public getBlackLogo(): string {
    return this.blackLogo;
  }

  public getLoginBackgroundImage(): string | null {
    return this.loginBackgroundImage;
  }

  public getCompanyName(): string {
    return this.companyName;
  }

  public getCompanyUrl(): string {
    return this.companyUrl;
  }

  public getStatusUrl(): string | null {
    return this.statusUrl;
  }
}
