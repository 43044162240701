import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiResponseModel } from '@mkx/models/api-response.model';
import { AppearanceModel } from '@mkx/models/appearance.model';

@Injectable({
  providedIn: 'root',
})
export class AppearanceService {
  private appearanceSubject$ = new BehaviorSubject<AppearanceModel>(null);
  private appearance$ = this.appearanceSubject$.asObservable();

  constructor(private http: HttpClient) {}

  init(): void {
    this.get().subscribe(res => {
      this.appearanceSubject$.next(res.getItem<AppearanceModel>());
    });
  }

  private get(): Observable<ApiResponseModel> {
    return this.http.get<any>('/appearance', {}).pipe(
      map(res => {
        return new ApiResponseModel(AppearanceModel, { data: res });
      })
    );
  }

  public getAppearance(): Observable<AppearanceModel> {
    return this.appearance$;
  }
}
