import { ErrorHandler } from '@angular/core';
import { environment } from '@mkx/environments/environment';
import * as Sentry from '@sentry/browser';

export class SentryErrorHandler implements ErrorHandler {
  constructor() {
    Sentry.init({
      dsn: environment.sentryDsn,
      release: environment.version,
      integrations(integrations) {
        return integrations.filter(
          integration => integration.name !== 'Breadcrumbs'
        );
      },
      ignoreErrors: ['Expired JWT Token'],
      beforeSend(event: any, hint?: any): Promise<any> | null {
        const chunkFailedMessage = /Loading chunk [\d]+ failed/;
        const isCypress = window && (window as any).Cypress;
        if (chunkFailedMessage.test(hint?.originalException?.message)) {
          window.location.reload();
          return null;
        } else if (
          hint?.originalException?.error?.code === 401 ||
          hint?.originalException?.error?.code === 403 ||
          hint?.originalException?.error?.code === 428 ||
          hint?.originalException?.error?.code === 0 ||
          hint?.originalException?.message?.includes(
            'No activity within 45000 milliseconds'
          ) ||
          !!isCypress
        ) {
          return null;
        } else {
          return event;
        }
      },
    });
  }
  handleError(error) {
    if (error && error.status !== 401 && error.status !== 404) {
      Sentry.captureException(error.originalError || error);
    }
  }
}
export function provideErrorHandler() {
  if (environment.production) {
    return new SentryErrorHandler();
  } else {
    // DEBUG ONLY
    // return new SentryErrorHandler();
    return new ErrorHandler();
  }
}
