import { InjectionToken } from '@angular/core';

export const AppRoutes = {
  login: 'login',
  campaigns: 'campaigns',
  campaignGroups: 'campaign-groups',
  settings: 'settings',
  users: 'users',
  agencyAccounts: 'agency-accounts',
  advertiserAccounts: 'advertiser-accounts',
  networkAccounts: 'network-accounts',
  userAccounts: 'user-accounts',
  notFound: '404',
  maintenance: 'maintenance',
  ownMediaGroups: 'own-media-groups',
  reportingSchedules: 'reporting-schedules',
};
export type AppRoutes = (typeof AppRoutes)[keyof typeof AppRoutes];

export const APP_ROUTES = new InjectionToken<Record<string, string>>(
  'app routes',
  {
    providedIn: 'root',
    factory: () => AppRoutes,
  }
);
