import { APIErrorModel } from '@mkx/models/api-error.model';

export class ApiErrorResponseModel {
  private errors: APIErrorModel[] = [];

  constructor(body) {
    body.errors.forEach(element => {
      this.errors.push(
        new APIErrorModel(
          element.message ?? null,
          element.key ?? null,
          element.property ?? null
        )
      );
    });
  }

  getErrors(): APIErrorModel[] {
    return this.errors;
  }
}
