import { InjectionToken } from '@angular/core';
import { NETWORK_ADS } from '@mkx/interfaces/common/INetworkType';

export interface IEndpoints {
  ads: (networkAdId: number) => string;
  auth: Record<string, string>;
  campaigns: {
    campaigns: string;
    logs: (campaignId: number) => string;
    notesPost: string;
    notes: (campaignId: number) => string;
  };
  exchangeRates: string;
  facebookAuth: string;
  facebookIntegration: string;
  groups: {
    campaigns: string;
    count: string;
    info: (campaignId: number) => string;
    elements: (campaignId: number) => string;
    downloadAggregatedExcelReport: (campaignGroupId: number) => string;
  };
  networkAccounts: {
    accounts: string;
    networkIdOptions: (network: NETWORK_ADS) => string;
  };
  ownMediaGroups: {
    get: (ownMediaGroupId: number) => string;
    getExcelReport: (ownMediaGroupId: number) => string;
  };
  targetings: string;
}

export const AppEndpoints: IEndpoints = {
  ads: (networkAdId: number) => `/ads/${networkAdId}`,
  auth: {
    user: '/user',
    users: '/users',
    userMe: '/me',
    confirm: '/users/confirm',
    userPassword: '/users/password',
    loginCheck: '/login',
    refreshToken: '/token/refresh',
  },
  campaigns: {
    campaigns: '/campaigns',
    notesPost: '/campaigns/notes',
    logs: (campaignId: number) => `/campaigns/${campaignId}/logs`,
    notes: (campaignId: number) => `/campaigns/${campaignId}/notes`,
  },
  exchangeRates: '/exchangerates',
  facebookAuth: '/facebook/auth',
  facebookIntegration: '/facebook/integration',
  groups: {
    campaigns: '/groups/campaigns',
    count: '/groups/count',
    info: (campaignGroupId: number) =>
      `/groups/campaigns/${campaignGroupId}/reports`,
    elements: (campaignGroupId: number) =>
      `/groups/campaigns/${campaignGroupId}/elements`,
    downloadAggregatedExcelReport: (campaignGroupId: number) =>
      `/groups/${campaignGroupId}/report`,
  },
  networkAccounts: {
    accounts: '/networkaccounts',
    networkIdOptions: (network: NETWORK_ADS) =>
      `/networkaccounts/${network}/options`,
  },
  ownMediaGroups: {
    get: (ownMediaGroupId: number) => `/own-media-groups/${ownMediaGroupId}`,
    getExcelReport: (ownMediaGroupId: number) =>
      `/own-media-groups/${ownMediaGroupId}/excel-report`,
  },
  targetings: '/targetings',
};

export const APP_ENDPOINTS = new InjectionToken<IEndpoints>(
  'app http endpoints',
  {
    providedIn: 'root',
    factory: () => AppEndpoints,
  }
);
