import { Injectable, Injector } from '@angular/core';
import { Overlay } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { TOAST_REF, ToastRef } from '../utils/toast-ref';
import { TOAST_DATA, ToastData } from '../utils/toast-config';
import { ToastNotificationComponent } from '../toast-notification/toast-notification.component';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  private lastToast: ToastRef;

  constructor(private overlay: Overlay, private parentInjector: Injector) {}

  show(data: ToastData) {
    const positionStrategy = this.getPositionStrategy();
    const overlayRef = this.overlay.create({ positionStrategy });
    const toastRef = new ToastRef(overlayRef);
    this.lastToast = toastRef;
    const injector = this.getInjector(data, toastRef, this.parentInjector);
    const toastPortal = new ComponentPortal(
      ToastNotificationComponent,
      null,
      injector
    );
    overlayRef.attach(toastPortal);

    return toastRef;
  }

  getPositionStrategy() {
    return this.overlay
      .position()
      .global()
      .top(this.getPosition())
      .right('0px');
  }

  getPosition() {
    const lastToastIsVisible = this.lastToast && this.lastToast.isVisible();
    const position = lastToastIsVisible
      ? this.lastToast.getPosition().bottom
      : 20;
    return position - 30 + 'px';
  }

  getInjector(data: ToastData, toastRef: ToastRef, parentInjector: Injector) {
    return Injector.create({
      providers: [
        { provide: TOAST_DATA, useValue: data },
        { provide: TOAST_REF, useValue: toastRef },
      ],
      parent: parentInjector,
    });
  }
}
