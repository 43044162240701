export const NETWORK_ADS = {
  YOUTUBE: 'google_ads',
  GOOGLE: 'google_ads',
  FACEBOOK: 'facebook_ads',
  INSTAGRAM: 'facebook_ads',
  TIKTOK: 'tiktok_ads',
  DV360: 'dv360_ads',
  ADFORM: 'adform_ads',
  SPOTIFY: 'spotify_ads',
  TWITTER: 'twitter_ads',
  LINKEDIN: 'linkedin_ads',
  PINTEREST: 'pinterest_ads',
  AMAZON: 'amazon_ads',
  SNAPCHAT: 'snapchat_ads',
  REDDIT: 'reddit_ads',
} as const;
export type NETWORK_ADS = (typeof NETWORK_ADS)[keyof typeof NETWORK_ADS];
