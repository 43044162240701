import { InjectionToken, TemplateRef } from '@angular/core';

export const TOAST_TYPES = {
  WARNING: 'warning',
  INFO: 'info',
  SUCCESS: 'success',
  ERROR: 'error',
} as const;
export type ToastType = (typeof TOAST_TYPES)[keyof typeof TOAST_TYPES];

export class ToastData {
  type: ToastType;
  text?: string;
  title?: string;
  template?: TemplateRef<any>;
  templateContext?: Record<any, any>;
  timeout?: number; // how long toast should be displayed
  translate?: boolean;
}

export const TOAST_DATA = new InjectionToken<ToastData>('TOAST_DATA');
