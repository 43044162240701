import { CanActivate, Router } from '@angular/router';
import { Inject, Injectable } from '@angular/core';
import { APP_ROUTES } from '@mkx/configs/app-routes';
import { JwtService } from '@mkx/services/jwt.service';

@Injectable({
  providedIn: 'root',
})
export class RedirectIfIsAlreadyLoginGuard implements CanActivate {
  constructor(
    private jwtService: JwtService,
    private router: Router,
    @Inject(APP_ROUTES) private appRoutes
  ) {}

  public canActivate(): boolean {
    if (this.jwtService.isAuthenticated()) {
      this.router.navigate(['/' + this.appRoutes.campaignGroups]);
    }

    return true;
  }
}
