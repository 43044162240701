import { Expose, plainToInstance } from 'class-transformer';

export class DatetimeModel {
  @Expose({ groups: ['read'], name: 'date' })
  private date: string;

  @Expose({ groups: ['read'], name: 'timezone_type' })
  private timezoneType: number;

  @Expose({ groups: ['read'], name: 'timezone' })
  private timezone: string;

  static buildFromAPIResponse(body: any): any {
    return plainToInstance(DatetimeModel, body, { groups: ['read'] });
  }

  public getDate(): string {
    return this.date;
  }

  public setDate(date: string): void {
    this.date = date;
  }

  public getTimezoneType(): number {
    return this.timezoneType;
  }

  public getTimezone(): string {
    return this.timezone;
  }
}
