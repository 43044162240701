import { Expose, Exclude, plainToInstance } from 'class-transformer';
import { staticImplementsDecorator } from '@mkx/decorators/static-implements.decorator';
import { ApiResponseEntityBuilderInterface } from '@mkx/interfaces/common/api-response-entity-builder.interface';
import { SelectOptionInterface } from '@mkx/interfaces/common/select-option.interface';
import { IconModel } from '@mkx/models/icon.model';

@staticImplementsDecorator<ApiResponseEntityBuilderInterface>()
export class IndustryModel implements SelectOptionInterface<IndustryModel> {
  @Exclude()
  private selected = false;

  @Expose({ groups: ['get'], name: 'id' })
  private id: number;

  @Expose({ groups: ['get'], name: 'name' })
  private name: string;

  constructor(id: number, name: string) {
    this.id = id;
    this.name = name;
  }

  static buildFromAPIResponse(body: any): any {
    return plainToInstance(IndustryModel, body, { groups: ['get'] });
  }

  public getId(): number {
    return this.id;
  }

  public getName(): string {
    return this.name;
  }

  public selectOptionGetValue(): IndustryModel {
    return this;
  }

  public selectOptionGetLabel(): string {
    return this.name;
  }

  public selectOptionGetLabelProperty(): string {
    return 'name';
  }

  public selectOptionIsDisabled(): boolean {
    return false;
  }

  public selectOptionSelect() {
    this.selected = true;
  }

  public selectOptionDeselect() {
    this.selected = false;
  }

  public selectOptionIsSelected(): boolean {
    return this.selected;
  }

  public selectOptionGetIdentifier(): any {
    return this.id;
  }

  public selectOptionGetIcon(): IconModel | null {
    return null;
  }

  public selectOptionGetColorClass(): string | null {
    return null;
  }

  public selectOptionGetDescription(): string | null {
    return null;
  }

  public selectOptionLabelIsUrl(): boolean {
    return false;
  }
}
