import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { environment } from '@mkx/environments/environment';
import { plainToInstance } from 'class-transformer';
import { EnvironmentStyleModel } from '@mkx/models/environment-style.model';
import pSBC from 'shade-blend-color';

@Injectable({
  providedIn: 'root',
})
export class GlobalStylesService {
  private rootStyle: CSSStyleDeclaration = this._document.documentElement.style;
  private styles: EnvironmentStyleModel;

  constructor(
    @Inject(DOCUMENT) private _document: HTMLDocument,
    private titleService: Title
  ) {
    this.styles = plainToInstance(EnvironmentStyleModel, environment.styles, {
      groups: ['read'],
    });
  }

  getStyles(): EnvironmentStyleModel {
    return this.styles;
  }

  setColors() {
    const primaryColor = this.styles.getPrimaryColor();
    const secondaryColor = this.styles.getSecondaryColor();

    this.rootStyle.setProperty(
      '--primary-color-light',
      pSBC(0.3, primaryColor)
    );
    this.rootStyle.setProperty('--primary-color', primaryColor);
    this.rootStyle.setProperty(
      '--primary-color-dark',
      pSBC(-0.3, primaryColor)
    );

    this.rootStyle.setProperty(
      '--secondary-color-light',
      pSBC(0.6, secondaryColor)
    );
    this.rootStyle.setProperty('--secondary-color', secondaryColor);
    this.rootStyle.setProperty(
      '--secondary-color-dark',
      pSBC(-0.3, secondaryColor)
    );
  }

  setFavicon(favicon?) {
    const styles = environment.styles;
    const newFavicon = favicon || styles.favicon;
    this._document
      .getElementById('appFavicon')
      .setAttribute('href', newFavicon);
  }

  public setTitle(title?: string) {
    const newTitle = title || environment.appTitle;
    this.titleService.setTitle(newTitle);
  }
}
