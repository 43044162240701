export class APIErrorModel {
  private message: string;
  private property: string;
  private key: string;

  constructor(message?: string, key?: string, property?: string) {
    this.message = message;
    this.property = property;
    this.key = key;
  }

  getMessage(): string {
    return this.message;
  }

  getProperty(): string {
    return this.property;
  }

  getKey(): string {
    return this.key;
  }
}
