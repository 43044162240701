export const environment = {
  production: true,
  apiUrl: 'https://api.bb.monkeyflux.com/api',
  appUrl: 'https://babuin2.monkeyflux.com/',
  appTitle: 'MonkeyFlux',
  version: 'VERSION',
  sentryDsn:
    'https://96fb6c05ae5644e8af058bdac328251d@o263356.ingest.sentry.io/5614153',
  amcharts: {
    chartsV4LicenseKey: 'CH377650283',
    chartsV5LicenseKey: 'AM5C377650283',
    mapsV5LicenseKey: 'AM5M377650283',
  },
  styles: {
    // Colors have to be hex
    primary_color: '#1f2937',
    secondary_color: '#4f46e5',
    white_logo: './assets/logos/monkey/white-monkey-logo.png',
    black_logo: './assets/logos/monkey/black-monkey-logo.png',
    company_name: 'Monkeyflux S.L.',
    favicon: 'favicon.ico',
    status_url: 'https://stats.uptimerobot.com/q2nWRcBmN',
  },
  googleMapApi: 'AIzaSyALh4aNXBGiIup9eLrMK0NtrqJ5Dm7MCK0',
  noticeableProjectId: '63LWRzGg8ehYSFF4FXBL',
  noticeableWidgetId: '73ghXqHC8KZhgCEfJFmA',
  chatSpellId: '23d2c0ae-3c9e-4f82-b796-01bc43cb5245',
  syncfusionLicense:
    'ORg4AjUWIQA/Gnt2VVhkQlFadVdJXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxQdkZiWX9YdH1VR2VeV0w=',
};
