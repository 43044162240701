import { NgModule } from '@angular/core';
import { RouterModule, Routes, PreloadAllModules } from '@angular/router';
import { RedirectIfIsAlreadyLoginGuard } from '@mkx/guards/redirect-if-is-already-login.guard';

const appRoutes: Routes = [
  {
    path: '',
    redirectTo: 'campaign-groups',
    pathMatch: 'full',
  },
  {
    path: '',
    loadChildren: () =>
      import('./features/login/login.module').then(m => m.LoginModule),
    canActivate: [RedirectIfIsAlreadyLoginGuard],
  },
  {
    path: '',
    loadChildren: () =>
      import('./features/maintenance/maintenance.module').then(
        m => m.MaintenanceModule
      ),
  },
  {
    path: '',
    loadChildren: () =>
      import('./features/shell/main-shell.module').then(m => m.MainShellModule),
  },
  // 404 layout
  // {
  //   path: AppConfig.routes.notFound,
  //   loadChildren: () =>
  //     import('./layouts/not-found/not-found.module').then(m => m.NotFoundModule)
  // },
  // otherwise redirect to 404
  { path: '**', redirectTo: '/campaign-groups' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes, {
      preloadingStrategy: PreloadAllModules,
      onSameUrlNavigation: 'ignore',
      anchorScrolling: 'enabled',
      scrollPositionRestoration: 'enabled',
      scrollOffset: [0, 64], // [x, y]
    }),
  ],
  exports: [RouterModule],
})
export class AppRouting {}
