import { OverlayRef } from '@angular/cdk/overlay';
import { inject, InjectionToken } from '@angular/core';

export class ToastRef {
  constructor(private readonly overlay: OverlayRef) {}

  close() {
    this.overlay.dispose();
  }

  isVisible(): HTMLElement {
    return this.overlay && this.overlay?.overlayElement;
  }

  getPosition(): DOMRect {
    return this.overlay?.overlayElement?.getBoundingClientRect();
  }
}

export const TOAST_REF = new InjectionToken<ToastRef>('TOAST_REF', {
  providedIn: 'root',
  factory: () => new ToastRef(inject(OverlayRef)),
});
