import { BrowserModule } from '@angular/platform-browser';
import { ErrorHandler, NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { AppRouting } from './app.routing';
import { HttpClientModule } from '@angular/common/http';
import { TranslocoRootModule } from './transloco/transloco-root.module';
import { CoreModule } from './core.modules';
import { DialogModule } from '@ngneat/dialog';
import { OverlayModule } from '@angular/cdk/overlay';
import { NgxMaskModule } from 'ngx-mask';
import { provideErrorHandler } from '@mkx/services/sentry-config';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpCacheInterceptorModule } from '@ngneat/cashew';
import { registerLicense } from '@syncfusion/ej2-base';
import { environment } from '@mkx/environments/environment';

registerLicense(environment.syncfusionLicense);

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRouting,
    CoreModule,
    HttpClientModule,
    TranslocoRootModule,
    OverlayModule,
    NgxMaskModule.forRoot(),
    DialogModule.forRoot(),
    HttpCacheInterceptorModule.forRoot(),
  ],
  providers: [{ provide: ErrorHandler, useFactory: provideErrorHandler }],
  bootstrap: [AppComponent],
})
export class AppModule {}
