import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiResponseModel } from '@mkx/models/api-response.model';
import { TokenModel } from '@mkx/features/user-profile/models/token.model';

@Injectable({
  providedIn: 'root',
})
export class TokenPostService {
  constructor(private http: HttpClient) {}

  auth(credentials: {
    username: string;
    password: string;
    code?: string | null;
  }): Observable<TokenModel> {
    return this.http.post<TokenModel>('/login', credentials).pipe(
      map(res => {
        return new ApiResponseModel(TokenModel, {
          data: res,
        }).getItem<TokenModel>();
      })
    );
  }

  refresh(refreshToken: string): Observable<TokenModel> {
    return this.http
      .post<TokenModel>('/token/refresh', { refresh_token: refreshToken })
      .pipe(
        map(res => {
          return new ApiResponseModel(TokenModel, {
            data: res,
          }).getItem<TokenModel>();
        })
      );
  }
}
