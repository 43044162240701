import { Expose, plainToInstance } from 'class-transformer';
import { staticImplementsDecorator } from '@mkx/decorators/static-implements.decorator';
import { ApiResponseEntityBuilderInterface } from '@mkx/interfaces/common/api-response-entity-builder.interface';

@staticImplementsDecorator<ApiResponseEntityBuilderInterface>()
export class AppearanceModel {
  @Expose({ groups: ['read'], name: 'logo' })
  private logo: string | null;

  @Expose({ groups: ['read'], name: 'company' })
  private company: string | null;

  @Expose({ groups: ['read'], name: 'companyUrl' })
  private companyUrl: string | null;

  @Expose({ groups: ['read'], name: 'statusUrl' })
  private statusUrl: string | null;

  @Expose({ groups: ['read'], name: 'showFooterPoweredBy' })
  private showFooterPoweredBy: boolean | null;

  static buildFromAPIResponse(body: any): any {
    return plainToInstance(AppearanceModel, body, { groups: ['read'] });
  }

  public getLogo(): string | null {
    return this.logo;
  }

  public getCompany(): string | null {
    return this.company;
  }

  public getCompanyUrl(): string | null {
    return this.companyUrl;
  }

  public getStatusUrl(): string | null {
    return this.statusUrl;
  }

  public getShowFooterPoweredBy(): boolean | null {
    return this.showFooterPoweredBy;
  }
}
