import { InstanceLoaderService } from '@mkx/services/instance-loader/instance-loader.service';
import { ApiResponseEntityBuilderInterface } from '@mkx/interfaces/common/api-response-entity-builder.interface';

export class ApiResponseModel {
  private data: any;
  private page?: number;
  private limit?: number;
  private total_count?: number;
  private number_of_pages?: number;
  private builder: ApiResponseEntityBuilderInterface;

  constructor(builder: ApiResponseEntityBuilderInterface | null, response) {
    this.page = response.page ?? null;
    this.limit = response.limit ?? null;
    this.total_count = response.total_count ?? null;
    this.number_of_pages = response.number_of_pages ?? null;
    this.builder = builder;
    this.setData(response.data);
  }

  private setData(data) {
    if (null !== this.builder) {
      this.setDataFromInstanceLoader(data);
    } else {
      this.data = data;
    }
  }

  private setDataFromInstanceLoader(data) {
    if (Array.isArray(data)) {
      const elements = [];
      data.forEach(element => {
        elements.push(InstanceLoaderService.getInstance(this.builder, element));
      });
      this.data = elements;
    } else {
      this.data = InstanceLoaderService.getInstance(this.builder, data);
    }
  }

  getItem<T>(): T {
    if (Array.isArray(this.data)) {
      throw new Error();
    }

    return this.data;
  }

  getItems<T>(): T[] {
    if (false === Array.isArray(this.data)) {
      throw new Error();
    }

    return this.data;
  }

  public hasItems(): boolean {
    return this.data !== null;
  }

  getPage(): number | null {
    return this.page;
  }

  onLastPage(): boolean {
    return this.page === this.number_of_pages || this.total_count === 0;
  }

  getTotalCount(): number {
    return this.total_count;
  }
}
