import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class RefreshTokenService {
  private key = 'refreshToken';

  constructor() {}

  getToken(): string {
    return localStorage.getItem(this.key);
  }

  saveToken(token: string) {
    localStorage.setItem(this.key, token);
  }

  destroyToken() {
    localStorage.removeItem(this.key);
  }
}
