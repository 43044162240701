import { Component, OnInit } from '@angular/core';
import { AuthService } from '@mkx/services/auth-service/auth.service';
import { GlobalStylesService } from '@mkx/services/global-style/global-style.service';
import { environment } from '@mkx/environments/environment';
import * as am4core from '@amcharts/amcharts4/core';
import * as am5 from '@amcharts/amcharts5';

@Component({
  selector: 'mkx-root',
  template: `<router-outlet></router-outlet>`,
})
export class AppComponent implements OnInit {
  constructor(
    private authService: AuthService,
    private gStyle: GlobalStylesService
  ) {
    // get the very first path that user navigates to from outside (copy-paste into bar, link etc...)
    this.authService.redirectUrl =
      window.location.pathname + window.location.search;
  }

  ngOnInit(): void {
    this.gStyle.setColors();
    this.gStyle.setTitle();
    this.gStyle.setFavicon();
    this.initAmChartsLicenses();
  }

  initAmChartsLicenses(): void {
    am4core.addLicense(environment.amcharts.chartsV4LicenseKey);
    am5.addLicense(environment.amcharts.mapsV5LicenseKey);
    am5.addLicense(environment.amcharts.chartsV5LicenseKey);
  }
}
