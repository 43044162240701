import {
  Component,
  Output,
  EventEmitter,
  Input,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';

@Component({
  selector: 'mkx-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class IconComponent implements OnInit {
  @Input() icon: string;
  @Input() size: '4' | '5' | '6' | '7' | '8' | '12' = '5';
  @Input() color:
    | 'none'
    | 'default'
    | 'white'
    | 'yellow'
    | 'secondary'
    | 'blue'
    | 'red'
    | 'green' = 'default';
  @Input() hover = true;
  @Output() clickChange = new EventEmitter();

  _classes: string;

  public get classes(): string {
    return this._classes;
  }

  @Input() set classes(classes: string | null) {
    if (!classes) {
      classes = '';
    }
    this._extraClasses = `${classes}`;
    this.ngOnInit();
  }

  _extraClasses = '';

  get extraClasses() {
    return this._extraClasses;
  }

  ngOnInit(): void {
    let colorClasses =
      this.color !== 'none' ? `tailwind-icon-color-${this.color}` : '';

    if (this.hover) {
      colorClasses += ` tailwind-icon-color-${this.color}-hover`;
    }

    this._classes = `${this._extraClasses} tailwind-icon-size-${this.size} ${colorClasses} tailwind-icon-transition`;
  }
}
