import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { TOAST_DATA, ToastData } from '../utils/toast-config';
import { TOAST_REF, ToastRef } from '../utils/toast-ref';
import { toastAnimations, ToastAnimationState } from '../utils/toast-animation';
import { TRANSLOCO_SCOPE } from '@ngneat/transloco';
import { AnimationEvent } from '@angular/animations';

@Component({
  selector: 'mkx-toast-notification',
  templateUrl: './toast-notification.component.html',
  styleUrls: ['./toast-notification.component.css'],
  providers: [
    {
      provide: TRANSLOCO_SCOPE,
      useValue: 'toast-messages',
    },
  ],
  animations: [toastAnimations.fadeToast],
})
export class ToastNotificationComponent implements OnInit, OnDestroy {
  public animationState: ToastAnimationState = 'default';
  public iconType: string;
  public shouldTranslate: boolean;
  private intervalId: number;

  constructor(
    @Inject(TOAST_DATA) readonly data: ToastData,
    @Inject(TOAST_REF) readonly ref: ToastRef
  ) {
    this.iconType = data.type === 'success' ? 'done' : data.type;
    this.shouldTranslate = data.translate ?? true;
  }

  ngOnInit() {
    this.intervalId = setTimeout(
      () => (this.animationState = 'closing' as any),
      this.data.timeout || 3000
    );
  }

  ngOnDestroy() {
    clearTimeout(this.intervalId);
  }

  close() {
    this.ref.close();
  }

  onFadeFinished(event: AnimationEvent) {
    const toState = event.toState;
    const isFadeOut = toState === 'closing';
    const itFinished = this.animationState === 'closing';

    if (isFadeOut && itFinished) {
      this.close();
    }
  }

  /**
   * Keep displaying on hover
   */

  onMouseEnter() {
    this.animationState = 'default';
    clearTimeout(this.intervalId);
  }

  // trigger timeout again
  onMouseLeave() {
    this.ngOnInit();
  }
}
