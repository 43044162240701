import { ROLES } from '@mkx/constants/roles';
import { ApiResponseEntityBuilderInterface } from '@mkx/interfaces/common/api-response-entity-builder.interface';
import { staticImplementsDecorator } from '@mkx/decorators/static-implements.decorator';
import { DatetimeModel } from '@mkx/models/datetime.model';
import { getBrowserLang } from '@ngneat/transloco';
import { Expose, plainToInstance, Transform, Type } from 'class-transformer';
import { AccountModel } from '@mkx/models/account.model';
import 'reflect-metadata';
import { SelectOptionInterface } from '@mkx/interfaces/common/select-option.interface';
import { IconModel } from '@mkx/models/icon.model';
import { BasicSelectOptionModel } from '@mkx/models/basic-select-option.model';
import { PLATFORM } from '@mkx/interfaces/common/IPlatformType';

@staticImplementsDecorator<ApiResponseEntityBuilderInterface>()
export class UserModel implements SelectOptionInterface<UserModel> {
  @Expose({ groups: ['read', 'get'], name: 'id' })
  private id: number;

  @Expose({ groups: ['read', 'create', 'get', 'post'], name: 'email' })
  private email: string;

  @Expose({ name: 'password' })
  private password: string;

  @Expose({ groups: ['read', 'create', 'get', 'post'], name: 'name' })
  private name: string;

  @Expose({ groups: ['read', 'create', 'get', 'post'], name: 'surname' })
  private surname: string;

  @Expose({ groups: ['read', 'get'], name: 'profile_photo' })
  private profilePhoto: string | null;

  @Expose({ groups: ['read', 'create', 'get', 'post'], name: 'roles' })
  private roles: ROLES[];

  @Expose({ groups: ['read', 'get'], name: 'language' })
  private language: 'es' | 'en';

  @Type(() => DatetimeModel)
  @Expose({ groups: ['read', 'get'], name: 'last_login' })
  private lastLogin: DatetimeModel | null;

  @Expose({ groups: ['read', 'get'], name: 'can_create_campaigns' })
  private canCreateCampaigns: boolean;

  @Expose({ groups: ['read', 'get'], name: 'can_update_campaigns' })
  private canUpdateCampaigns: boolean;

  @Expose({ groups: ['read', 'get'], name: 'can_see_trader_view' })
  private canSeeTraderView: boolean;

  @Expose({ groups: ['read', 'get'], name: 'show_changelog_widget' })
  private showChangelogWidget: boolean;

  @Expose({ groups: ['read', 'get'], name: 'show_support_chat' })
  private showSupportChat: boolean;

  @Expose({ groups: ['read', 'get'], name: 'has_agencies' })
  private hasAgencies: boolean;

  @Expose({ groups: ['read', 'get'], name: 'show_demos' })
  private showDemos: boolean;

  @Expose({ groups: ['read', 'get'], name: 'is_presentation_mode' })
  private isPresentationMode: boolean;

  @Expose({ groups: ['read', 'get'], name: 'is_demo_mode' })
  private isDemoMode: boolean;

  @Expose({ groups: ['read', 'get'], name: 'is_infinity_user' })
  private isInfinityUser: boolean;

  @Transform(({ value }) => configThousandSeparator(value), {
    toClassOnly: true,
  })
  @Expose({ groups: ['read', 'get'], name: 'thousands_separator' })
  private thousandsSeparator: ',' | '.' | null;

  @Transform(({ value }) => configDecimalSeparator(value), {
    toClassOnly: true,
  })
  @Expose({ groups: ['read', 'get'], name: 'decimals_separator' })
  private decimalsSeparator: ',' | '.' | null;

  @Type(() => AccountModel)
  @Expose({ groups: ['read', 'get'], name: 'accounts' })
  private accounts: AccountModel[] | null;

  @Expose({ groups: ['read', 'get'], name: 'twitter_accounts' })
  private twitterAccounts: string[];

  @Expose({ groups: ['read', 'get'], name: 'youtube_channels' })
  private youtubeChannels: string[];

  @Expose({ groups: ['read', 'get'], name: 'tiktok_accounts' })
  private tikTokAccounts: string[];

  @Expose({ groups: ['read', 'create', 'get', 'post'], name: 'login_disabled' })
  private login_disabled: boolean;

  constructor(
    email: string,
    name: string,
    surname: string,
    roles: ROLES[],
    accounts: AccountModel[],
    login_disabled: boolean
  ) {
    this.email = email;
    this.name = name;
    this.surname = surname;
    this.roles = roles;
    this.accounts = accounts;
    this.login_disabled = login_disabled;
  }

  static buildFromAPIResponse(body: any): any {
    return plainToInstance(UserModel, body, { groups: ['read', 'get'] });
  }

  public getId(): number {
    return this.id;
  }

  public setId(value: number) {
    this.id = value;
  }

  public getEmail(): string {
    return this.email;
  }

  public setEmail(value: string) {
    this.email = value;
  }

  public getPassword(): string {
    return this.password;
  }

  public setPassword(value: string) {
    this.password = value;
  }

  public getName(): string {
    return this.name;
  }

  public setName(value: string) {
    this.name = value;
  }

  public getSurname(): string {
    return this.surname;
  }

  public setSurname(value: string) {
    this.surname = value;
  }

  public getRoles(): ROLES[] {
    return this.roles;
  }

  public getRolesSelectionModel(): BasicSelectOptionModel[] {
    const rolesOptions = [
      { id: ROLES.ROLE_USER, name: 'User' },
      { id: ROLES.ROLE_ADMIN, name: 'Admin' },
      { id: ROLES.ROLE_SUPER_ADMIN, name: 'Super Admin' },
    ];
    const body = rolesOptions.filter(role => this.roles.includes(role.id));
    return body.map(roleOption =>
      BasicSelectOptionModel.buildFromAPIResponse(roleOption)
    );
  }

  public get superAdmin(): boolean {
    return this.getRoles().includes(ROLES.ROLE_SUPER_ADMIN);
  }

  public setRoles(value: ROLES[]) {
    this.roles = value;
  }

  public getLanguage(): 'es' | 'en' {
    return this.language;
  }

  public setLanguage(value: 'es' | 'en') {
    this.language = value;
  }

  public getLastLogin(): DatetimeModel | null {
    return this.lastLogin;
  }

  public setLastLogin(value: DatetimeModel) {
    this.lastLogin = value;
  }

  public getCanCreateCampaigns(): boolean {
    return this.canCreateCampaigns;
  }

  public setCanCreateCampaigns(value: boolean) {
    this.canCreateCampaigns = value;
  }

  public getCanUpdateCampaigns(): boolean {
    return this.canUpdateCampaigns;
  }

  public setCanUpdateCampaigns(value: boolean) {
    this.canUpdateCampaigns = value;
  }

  public getCanSeeTraderView(): boolean {
    return this.canSeeTraderView;
  }

  public setCanSeeTraderView(value: boolean) {
    this.canSeeTraderView = value;
  }

  public getShowChangelogWidget(): boolean {
    return this.showChangelogWidget;
  }

  public getShowSupportChat(): boolean {
    return this.showSupportChat;
  }

  public getHasAgencies(): boolean {
    return this.hasAgencies;
  }

  public getShowDemos(): boolean {
    return this.showDemos;
  }

  public setShowDemos(value: boolean) {
    this.showDemos = value;
  }

  public getIsPresentationMode(): boolean {
    return this.isPresentationMode;
  }

  public setIsPresentationMode(value: boolean) {
    this.isPresentationMode = value;
  }

  public getIsDemoMode(): boolean {
    return this.isDemoMode;
  }

  public setIsDemoMode(value: boolean) {
    this.isDemoMode = value;
  }

  public getIsInfinityUser(): boolean {
    return this.isInfinityUser;
  }

  public getThousandsSeparator(): ',' | '.' | null {
    return this.thousandsSeparator;
  }

  public setThousandsSeparator(value: ',' | '.' | null) {
    this.thousandsSeparator = value;
  }

  public getDecimalsSeparator(): ',' | '.' | null {
    return this.decimalsSeparator;
  }

  public setDecimalsSeparator(value: ',' | '.' | null) {
    this.decimalsSeparator = value;
  }

  public getLoginDisabled(): boolean {
    return this.login_disabled;
  }

  public setLoginDisabled(value: boolean) {
    this.login_disabled = value;
  }

  public getAccounts(): AccountModel[] | null {
    return this.accounts;
  }

  public isUser(): boolean {
    return this.roles.includes(ROLES.ROLE_USER) || this.isAdmin();
  }

  public isAdmin(): boolean {
    return this.roles.includes(ROLES.ROLE_ADMIN) || this.isSuperAdmin();
  }

  public isSuperAdmin(): boolean {
    return this.roles.includes(ROLES.ROLE_SUPER_ADMIN);
  }

  public hasRoles(roles: ROLES[]): boolean {
    const userRoles = this.roles;
    const intersection = roles.filter(x => userRoles.includes(x));

    return !!intersection.length;
  }

  public getTwitterAccounts(): string[] {
    return this.twitterAccounts;
  }

  public getYoutubeChannels(): string[] {
    return this.youtubeChannels;
  }

  public getTikTokAccounts(): string[] {
    return this.tikTokAccounts;
  }

  public getProfilePhoto(): string | null {
    return this.profilePhoto;
  }

  @Expose({ groups: ['create', 'post', 'put'], name: 'accounts' })
  public getAccountsIds(): { id: number }[] {
    return this.accounts
      ? this.accounts.map(account => {
          return { id: account.getId() };
        })
      : [];
  }

  public networkIsLinked(network: string): boolean {
    switch (network) {
      case PLATFORM.TIKTOK:
        return this.tikTokAccounts.length > 0;
      case PLATFORM.TWITTER:
        return this.twitterAccounts.length > 0;
      case PLATFORM.YOUTUBE:
        return this.youtubeChannels.length > 0;
      default:
        return false;
    }
  }

  selectOptionGetDescription(): string | null {
    return this.email;
  }

  selectOptionGetIcon(): IconModel | null {
    return null;
  }

  public selectOptionGetColorClass(): string | null {
    return null;
  }

  selectOptionGetIdentifier(): any {
    return this.id;
  }

  selectOptionGetLabel(): string {
    return this.name + ' ' + this.surname;
  }

  selectOptionGetLabelProperty(): string {
    return 'email';
  }

  selectOptionGetValue(): UserModel {
    return this;
  }

  selectOptionIsDisabled(): boolean {
    return false;
  }

  selectOptionIsSelected(): boolean {
    return false;
  }

  selectOptionDeselect() {}

  selectOptionLabelIsUrl(): boolean {
    return false;
  }

  selectOptionSelect() {}

  public tvFilter(): boolean {
    return this.getCanSeeTraderView() && !this.getIsPresentationMode();
  }

  public setUpDefaultSeparators(
    decimalsSeparator: ',' | '.',
    thousandsSeparator: ',' | '.'
  ): void {
    this.decimalsSeparator = decimalsSeparator;
    this.thousandsSeparator = thousandsSeparator;
  }
}

function configDecimalSeparator(value): string {
  if (!value) {
    const browserLang = getBrowserLang();
    return browserLang === 'es' ? ',' : '.';
  }
  return value;
}

function configThousandSeparator(value): string {
  if (!value) {
    const browserLang = getBrowserLang();
    return browserLang === 'es' ? '.' : ',';
  }
  return value;
}
